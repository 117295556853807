var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "backend-parent-autocomplete" },
    [
      _c("v-autocomplete", {
        staticClass: "mt-n2",
        attrs: {
          clearable: "",
          items: _vm.parentCustomerList,
          loading: _vm.loading,
          "search-input": _vm.search,
          color: "info",
          "item-text": "name",
          "item-value": "id",
          label: _vm.parentText,
          "return-object": "",
          "close-on-content-click": "",
        },
        on: {
          "update:searchInput": function ($event) {
            _vm.search = $event
          },
          "update:search-input": function ($event) {
            _vm.search = $event
          },
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.fireEnterEvent()
          },
          blur: function ($event) {
            return _vm.pushParentCustomer()
          },
          "click:clear": function ($event) {
            return _vm.removeParentCustomer()
          },
        },
        model: {
          value: _vm.parent,
          callback: function ($$v) {
            _vm.parent = $$v
          },
          expression: "parent",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }